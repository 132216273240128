<template>
	<div v-if="data">
		<div
			class="card"
			:style="{ backgroundColor: data.color }"
			@click="openRandom"
		>
			{{ disc }}
		</div>
		<div v-if="false">
			<div v-for="l in links" :key="l" class="sug-links">
				<router-link
					:to="'/wiki/' + l"
					:style="{ color: data.color, borderColor: data.color }"
				>
					{{ format(l) }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { shuffle } from "@/common/verycommon.js";

export default {
	props: {
		data: Object,
		disc: String,
	},
	data() {
		return {
			links: this.data.links,
			emoji: this.data.emoji,
		};
	},
	methods: {
		format(l) {
			return l.replace(/_/g, " ");
		},
		openRandom() {
			const link = shuffle(this.links)[0];
			this.$router.push("/wiki/" + link);
		},
	},
};
</script>

<style scoped>
.card {
	width: 300px;
	height: 200px;
	border-radius: 12px;
	margin: 40px 20px 20px 20px;
	color: white;
	font-size: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sug-links {
	display: flex;
	padding-left: 30px;
}
.sug-links a {
	text-align: left;
	text-decoration: none;
	padding-bottom: 2px;
	border-bottom: 0.5px solid black;
}
</style>
