<template>
	<div class="feedbox">
		<div v-if="!showInput" class="feedbackButton" @click="showInput = true">
			Feedback?
		</div>
		<div v-if="showInput" @click="clear" class="backClick" />
		<div v-if="showInput" class="inputBox">
			<textarea v-model="input" v-if="!success" />
			<div class="sendContainer" v-if="!success">
				<div class="sendButton" @click="sendFeedback" v-if="!loading">
					share feedback
					<v-icon class="sendIcon" name="arrow-right" scale="1.25" />
				</div>
				<div v-else style="color: var(--brown)" class="sendButton textSending">
					Sending...
				</div>
			</div>
			<div v-if="success" class="thanksMessage">
				Thank you for your feedback!
			</div>
		</div>
	</div>
</template>

<script>
import { addFeedback } from "./db.js";
export default {
	data() {
		return {
			showInput: false,
			success: false,
			loading: false,
			input: "",
		};
	},
	methods: {
		clear() {
			this.success = false;
			this.showInput = false;
			this.loading = false;
		},
		sendFeedback() {
			if (!this.input.trim()) return;
			this.loading = true;
			addFeedback(this.input.trim())
				.then(() => {
					this.input = "";
					this.success = true;
					setTimeout(() => {
						this.clear();
					}, 2000);
				})
				.catch((e) => {
					open(
						"mailto:victorforissier@gmail.com?subject=" +
							encodeURI("Your feedback title") +
							"&body=" +
							encodeURI(
								"Your feedback could not be sent because of an error on our side... Could you send it here instead ? Thanks!\n\n" +
									this.input
							)
					);
				});
		},
	},
};
</script>

<style scoped>
.feedbox {
	position: fixed;
	bottom: 16px;
	right: 16px;
}
.feedbackButton {
	color: white;
	background-color: var(--beige);
	padding: 6px 16px;
	border-radius: 50px;
	cursor: pointer;
}
.inputBox,
textarea {
	border-radius: 6px;
}
.inputBox {
	z-index: 2;
	background-color: white;
	border: 1px solid var(--beige);
}
textarea {
	padding: 8px;
	outline: none;
	border: 0;
	resize: none;
	height: 300px;
	width: 450px;
	font-size: 16px;
}
.sendContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.sendButton {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-left: auto;
	padding: 6px 16px;
	font-size: 18px;
	margin: 8px;
	border-radius: 6px;
	color: white;
	background-color: var(--brown);
	cursor: pointer;
}
.textSending {
	background-color: white;
	color: var(--brown);
}
.sendIcon {
	margin-left: 12px;
	color: white;
}
.thanksMessage {
	color: var(--brown);
	font-size: 20px;
	height: 300px;
	width: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.backClick {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	z-index: -1;
}
</style>
