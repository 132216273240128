import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../dashboard/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dashboard",
		component: Dashboard,
	},
	{
		path: "/wiki/:page",
		name: "Wiki",
		component: () => import("../wiki/Wiki.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
