import { get_main_user_ref, doc_handler } from "@/firebase";

export async function getLastArticle() {
	return get_main_user_ref()
		.collection("page_pg")
		.orderBy("lastUpdate", "desc")
		.limit(1)
		.get()
		.then((docs) => (docs.empty ? undefined : docs.docs.map(doc_handler)[0]));
}
