import {
	increment,
	doc_handler,
	get_main_user_ref,
	get_user_id,
	db,
} from "@/firebase";

export async function getProgress(page_id) {
	const user_ref = get_main_user_ref();
	const page_doc = user_ref.collection("page_pg").doc(page_id);
	return page_doc.get().then((doc) => {
		let res;
		if (doc.exists) res = doc.data();
		else res = {};
		res.id = doc.id;
		return res;
	});
}

export async function incProgress(page_id, content) {
	const user_ref = get_main_user_ref();
	const page_doc = user_ref.collection("page_pg").doc(page_id);
	page_doc.set({ currentIndex: increment(1), ...content }, { merge: true });
}

export async function getDPG(discipline) {
	const user_ref = get_main_user_ref();
	const user = await user_ref.get().then(doc_handler);
	return user[discipline] || 0;
}
export async function incDPG(discipline) {
	const user_ref = get_main_user_ref();
	user_ref.set(
		{
			[discipline]: increment(5),
		},
		{ merge: true }
	);
}
export async function saveMessage(mess) {
	const uid = get_user_id();
	db.collection("summary").add({
		...mess,
		creationTime: Date.now(),
		user_id: uid,
	});
}
