<template>
	<div class="home">
		<div class="logoRow">
			<img src="@/assets/logo.svg" class="logoImg" />
			<div class="logoText">Playful Wiki</div>
		</div>
		<div class="searchRow">
			<input
				placeholder="Search article"
				class="searchBar"
				v-model="searchInput"
				@keyup.enter="search"
			/>

			<div class="searchButton" @click="search">
				<v-icon name="search" scale="1" class="searchIcon" />
			</div>
		</div>
		<Previously />
		<div v-if="searchResNames">
			<div class="noRes" v-if="!searchResNames.length">
				No results for your search.
			</div>
			<div v-else class="searchResults">
				<router-link
					class="searchLink"
					v-for="(name, i) in searchResNames"
					:key="name"
					:to="searchResLinks[i].replace('https://en.wikipedia.org', '')"
				>
					{{ name }}
				</router-link>
				<a
					href="#"
					class="searchLink active"
					@click="
						searchResNames = '';
						searchResLinks = '';
						searchInput = '';
					"
				>
					clear search</a
				>
			</div>
		</div>
		<div class="cardsRow">
			<Card
				v-for="(data, key) in disciplines"
				:key="key"
				:disc="key"
				:data="data"
			/>
		</div>
	</div>
</template>

<script>
import Card from "./Card.vue";
import playlists from "./playlists.json";
import { searchWikipedia } from "./wikipedia.js";
import Previously from "./Previously.vue";

export default {
	name: "Home",
	components: { Card, Previously },
	data() {
		return {
			disciplines: playlists,
			searchInput: "",
			searchResNames: undefined,
			searchResLinks: undefined,
		};
	},
	methods: {
		search() {
			if (!this.searchInput.trim()) return;
			searchWikipedia(this.searchInput.trim()).then((res) => {
				const { resNames, resLinks } = res;
				this.searchResNames = resNames || [];
				this.searchResLinks = resLinks || [];
			});
		},
	},
};
</script>

<style scoped>
/* Logo row */
.home {
	padding: 0 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.logoRow {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 20px;
}
.logoImg {
	width: 150px;
}
.logoText {
	font-size: 50px;
	margin-left: 24px;
	color: var(--brown);
}
/* Search */
.searchResults {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.noRes {
	color: var(--brown);
	margin: 20px;
}
.searchLink {
	flex: 1;
	color: var(--brown);
	text-decoration: none;
	font-size: 20px;
	margin: 4px 0;
	padding-bottom: 3px;
}
.searchLink:hover {
	padding-bottom: 2px;
	border-bottom: 1px solid var(--brown);
}
.active {
	padding-bottom: 2px;
	border-bottom: 1px solid var(--brown);
}
.searchRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: auto;
}
.searchBar {
	flex: 1;
	width: 600px;
	height: 42px;
	padding: 8px 24px;
	border: 2px solid var(--beige);
	border-radius: 35px;
	font-size: 18px;
	position: relative;
	outline: none;
}
.searchButton {
	background-color: var(--brown);
	border-radius: 50px;
	margin-left: 8px;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	right: 0;
}
.searchIcon {
	color: white;
}
@import "./../wiki/css/common/tags.css";
.cardsRow {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}
</style>
