export async function searchWikipedia(input) {
	const url =
		"https://en.wikipedia.org/w/api.php" +
		"?action=opensearch&search=" +
		input +
		"&limit=42&namespace=0&format=json&origin=*";

	try {
		const req = await fetch(url);
		const json = await req.json();
		console.log(json);
		return { resNames: json[1], resLinks: json[3] };
	} catch (e) {
		console.error(e);
	}
}
