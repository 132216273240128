<template>
	<div v-if="isReady" class="prevRow">
		<span style="padding-right: 8px">Previously in Playful Wiki...</span>
		<router-link :to="'/wiki/' + article.title" class="articleLink">{{
			article.title
		}}</router-link>
		<span>
			– <span class="disc">{{ article.discipline }}</span></span
		>
		<div class="pgContainer">
			<ProgressBar
				v-if="dpg !== undefined"
				type="discipline"
				:pg="dpg"
				:discipline="article.discipline"
			/>
		</div>
	</div>
</template>

<script>
import ProgressBar from "@/common/ProgressBar.vue";
import { getDPG } from "@/wiki/utils/db.js";
import { getLastArticle } from "./db.js";

export default {
	components: {
		ProgressBar,
	},
	computed: {
		isReady() {
			return this.article !== undefined && this.dpg !== undefined;
		},
	},
	data() {
		return {
			article: undefined,
			dpg: undefined,
		};
	},
	mounted() {
		getLastArticle().then((article) => {
			if (!article) return;
			this.article = article;
			getDPG(this.article.discipline).then((dpg) => (this.dpg = dpg));
		});
	},
};
</script>

<style scoped>
.prevRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	width: 100%;
	padding: 16px;
	font-size: 24px;
}
.prevRow > span {
	padding-bottom: 3px;
}
.articleLink {
	text-decoration: none;
	padding-bottom: 3px;
	margin-right: 8px;
	border-bottom: 1px solid var(--beige);
}
.articleLink:hover {
	border-bottom: 1px solid var(--brown);
}
.disc {
	color: var(--brown);
}
.pgContainer {
	width: 30%;
	padding: 24px;
}
</style>
