<template>
	<div id="app">
		<router-view v-if="isReady && isDesktop" />
		<div v-if="!isDesktop" class="parentMess">
			<img src="@/assets/logo.svg" class="logo" />
			<div class="title">Playful Wiki</div>
			<div class="message">
				Sorry.... The mobile version doesn't work well yet. You can go on your
				laptop though.
			</div>
		</div>
		<Feedbox />
	</div>
</template>

<script>
import { auth } from "./firebase";
import Feedbox from "@/common/Feedbox.vue";

export default {
	components: {
		Feedbox,
	},
	data() {
		return {
			isReady: false,
		};
	},
	computed: {
		isDesktop() {
			return window.screen.width > 600;
		},
	},
	mounted() {
		auth.onAuthStateChanged((user) => {
			if (user) {
			} else {
				auth.signInAnonymously();
			}
			this.isReady = true;
		});
	},
};
</script>

<style scoped>
.parentMess {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	padding: 0 20px 15vh;
}
.logo {
	width: 40%;
}
.title {
	margin: 20px 0 40px;
	color: var(--brown);
	font-size: 42px;
}
.message {
	font-size: 24px;
}
</style>
<style>
@import "./App.css";
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 20px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
