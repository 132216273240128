import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

var firebaseConfig = {
	apiKey: "AIzaSyDG7Td_A54pnD3ExF6erJBJARUhJg6hq_c",
	authDomain: "playfulwiki.firebaseapp.com",
	projectId: "playfulwiki",
	storageBucket: "playfulwiki.appspot.com",
	messagingSenderId: "169689955399",
	appId: "1:169689955399:web:e98602e400768071d50562",
	measurementId: "G-6NW41X3G9G",
};
// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
	firebase.analytics();
}

//firebase.functions().useEmulator("localhost", 5001);

export const auth = firebase.auth();
export const emailCred = (email, psw) =>
	firebase.auth.EmailAuthProvider.credential(email, psw);
export const get_user_id = () => firebase.auth().currentUser.uid;

/* Firestore */
export const db = firebase.firestore();
export const increment = (count) =>
	firebase.firestore.FieldValue.increment(count);
export const get_main_user_ref = () => {
	const user_id = get_user_id();
	return db.collection("user").doc(user_id);
};
export const doc_handler = (doc) => {
	if (doc.exists) {
		let res = doc.data();
		res.id = doc.id;
		return res;
	} else return {};
};
