import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import firebase from "firebase/app";
import "firebase/analytics";

var firebaseConfig = {
	apiKey: "AIzaSyDG7Td_A54pnD3ExF6erJBJARUhJg6hq_c",
	authDomain: "playfulwiki.firebaseapp.com",
	projectId: "playfulwiki",
	storageBucket: "playfulwiki.appspot.com",
	messagingSenderId: "169689955399",
	appId: "1:169689955399:web:e98602e400768071d50562",
	measurementId: "G-6NW41X3G9G",
};
// Initialize Firebase

import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
Vue.component("v-icon", Icon);

import vmodal from "vue-js-modal";
Vue.use(vmodal);

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
	firebase.analytics();
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
