export function getDisciplineData(totalXP) {
	let XPtoNext = 20;
	for (let i = 0; i < 100; i++) {
		if (totalXP > XPtoNext) {
			totalXP -= XPtoNext;
			XPtoNext = XPtoNext * 1.1;
		} else {
			return { nextLevel: i + 1, XPtoNext, levelXP: totalXP };
		}
	}
}
