<template>
	<div v-if="isReady" class="PGRow">
		<div class="pgContainer">
			<div class="pgBar" :style="{ width: pgWidth + '%' }" />
		</div>
		<div class="goalLabel" :class="{ sectionGoalLabel: type === 'sections' }">
			<div v-if="type === 'discipline'" class="starContainer">
				<v-icon name="star" class="star" scale="2" />
				<div class="levelNum">{{ nextLevel }}</div>
			</div>
			<div v-else-if="type === 'sections'" class="sectionsText">
				{{ pg }} / {{ sectionsTotal }}
			</div>
		</div>
	</div>
</template>

<script>
import { getDisciplineData } from "./levels.js";
export default {
	props: {
		type: String,
		pg: Number,
		discipline: String,
		sectionsTotal: Number,
	},
	data() {
		return {
			nextLevel: undefined,
			//
			total: undefined,
			pgNum: undefined,
		};
	},
	mounted() {
		if (this.type === "discipline") {
			const { nextLevel, XPtoNext, levelXP } = getDisciplineData(this.pg);
			this.nextLevel = nextLevel;
			this.total = XPtoNext;
			this.pgNum = levelXP;
		} else if (this.type === "sections") {
			this.total = this.sectionsTotal;
			this.pgNum = this.pg;
		}
	},
	computed: {
		isReady() {
			return ![this.total, this.pgNum].includes(undefined);
		},
		pgWidth() {
			return this.convert2Perc(this.pgNum, this.total);
		},
	},
	methods: {
		convert2Perc(value, total) {
			return Math.round((value / total) * 100);
		},
	},
};
</script>

<style scoped>
* {
	overflow: visible !important;
}
.PGRow {
	display: flex;
	align-items: center;
	justify-content: left;
}
.goalLabel {
	position: relative;

	flex: 1;
}
.sectionGoalLabel {
	flex: 3.5;
	color: var(--brown);
	font-weight: bold;
}
.sectionsText {
	text-align: center;
	padding: 8px;
}
.pgContainer {
	height: 12px;
	flex: 8;
	border: 1px solid var(--beige);
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.33);
}
.pgBar {
	height: 10px;
	background-color: var(--brown);
	border-radius: 20px;
}
.starContainer {
	position: absolute;
	left: -20px;
	top: -17px;
}
.levelNum {
	top: 6px;
	left: 12px;
	position: absolute;
	font-weight: bold;
	font-size: 14px;
	color: var(--beige);
}
.star {
	color: var(--brown);
}
</style>
